@import "normalize.css";

html {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

*,
*::before,
*::after {
  -webkit-box-sizing: inherit;
  -moz-box-sizing: inherit;
  box-sizing: inherit;
}

body {
  font-family: "Open Sans", Arial, sans-serif;
  margin: 0;
}

.hidden {
  display: none;
}

